<template>
  <section-description-text
    background="#f5f5f5"
    max-width="700px"
    :description="documentToHtmlString(translations.signallerParagraph)"
  />
</template>

<script>
  import SectionDescriptionText from '@/views/sections/reusable/DescriptionText'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_SIGNALLERS } from '@/views/signallers/Index.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'SectionSignallersDescription',
    components: {
      SectionDescriptionText,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_SIGNALLERS, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
    },
  }
</script>
