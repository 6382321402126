<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const SIGNALLERS = '3C0Q7pkQOBeaH4spAUohy1'
  export const STORE_NAMESPACE_SIGNALLERS = 'page/signallers'
  export default {
    name: 'Signallers',

    metaInfo: { title: 'SIGNALLERS' },

    extends: View,

    mixins: [
      LoadSections([
        'sub-hero',
        'signallers-description',
        'trusted-us',
        'footer',
      ]),
      FetchPage(SIGNALLERS, STORE_NAMESPACE_SIGNALLERS),
    ],
    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
